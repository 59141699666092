'use client';
import { HEADER_HEIGHT } from '@/components/AppHeader';
import { SHORTCUT_KEYS } from '@/constants/shortcutKeys';
import { NAVIGATION_KEYS } from '@/constants/navigationKeys';
import { API } from '@/api';

export const classes = {
  wrapper: 'w-full h-full',
  body: 'flex',
  content: 'flex-1 h-full overflow-auto',
};

export const styles = {
  body: { height: `calc(100vh - ${HEADER_HEIGHT})` },
};

export function getShortcut(
  e: KeyboardEvent
): keyof typeof SHORTCUT_KEYS | null {
  switch (e.key?.toLowerCase()) {
    case 'p':
      return '\\P';
    case 'c':
      return '\\C';
    default:
      return null;
  }
}

export function getNavigation(
  e: KeyboardEvent
): keyof typeof NAVIGATION_KEYS | null {
  switch (e.key?.toLowerCase()) {
    case 'p':
      return '/P';
    case 'c':
      return '/C';
    case 'f':
      return '/F';
    default:
      return null;
  }
}

export function isIgnoredKey(e: KeyboardEvent) {
  return ['Shift', '/', '\\'].includes(e.key);
}

export async function fetchPracticeData(accountID: number) {
  const [practicePreferences, practiceUsers] = await Promise.all([
    API.Practices.fetchPracticePreferences(accountID),
    API.Practices.fetchPracticeUsers(accountID, {
      filters: [],
      pagination: { page: 1, pageSize: 100 },
    }),
  ]);
  return { practicePreferences, practiceUsers: practiceUsers.results };
}
