import { useAppState } from '@/providers/AppStateProvider';
import {
  ACCOUNTS,
  LOGIN,
  NAVIGATION,
  PROJECT,
  SEARCH,
  SHORTCUTS,
} from '@/constants/dialogs';
import { SHORTCUT_KEYS } from '@/constants/shortcutKeys';
import { NAVIGATION_KEYS } from '@/constants/navigationKeys';
import AccountsDialog from '@/dialogs/AccountsDialog';
import LoginDialog from '@/dialogs/LoginDialog';
import SearchDialog from '@/dialogs/SearchDialog';
import ShortcutsDialog from '@/dialogs/ShortcutsDialog';
import NavigationDialog from '@/dialogs/NavigationDialog';
import ProjectDialog from '@/dialogs/ProjectDialog';

export type DialogsManagerProps = {
  onSelectShortcut: (shortcut: keyof typeof SHORTCUT_KEYS) => void;
  onSelectNavigation: (shortcut: keyof typeof NAVIGATION_KEYS) => void;
};

export default function DialogsManager({
  onSelectShortcut,
  onSelectNavigation,
}: DialogsManagerProps) {
  const { activeDialogs, onCloseDialog } = useAppState();

  return (
    <>
      {activeDialogs[PROJECT] && (
        <ProjectDialog onClose={() => onCloseDialog(PROJECT)} />
      )}
      {activeDialogs[SEARCH] && (
        <SearchDialog onClose={() => onCloseDialog(SEARCH)} />
      )}
      {activeDialogs[SHORTCUTS] && (
        <ShortcutsDialog
          onSelect={onSelectShortcut}
          onClose={() => onCloseDialog(SHORTCUTS)}
        />
      )}
      {activeDialogs[NAVIGATION] && (
        <NavigationDialog
          onSelect={onSelectNavigation}
          onClose={() => onCloseDialog(NAVIGATION)}
        />
      )}
      {activeDialogs[ACCOUNTS] && (
        <AccountsDialog onClose={() => onCloseDialog(ACCOUNTS)} />
      )}
      {activeDialogs[LOGIN] && (
        <LoginDialog onClose={() => onCloseDialog(LOGIN)} />
      )}
    </>
  );
}
